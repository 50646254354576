import React, { useState, useEffect, useCallback } from 'react';
import { List, ListItem, ListItemText, Button, ListItemButton } from '@mui/material';
import { useAuth, UserService, ServiceData } from 'shared-lib';
import ServiceEdit from './ServiceEdit';

const ServiceList: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const [services, setServices] = useState<ServiceData[]>([]);
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
  const { user } = useAuth();
  const userService = UserService.getInstance();

  const fetchServices = useCallback(async () => {
    if (user) {
      const userServices = await userService.getUserServices(user.uid);
      setServices(userServices);
    }
  }, [user, userService]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const handleServiceEditClose = () => {
    setSelectedServiceId(null);
    fetchServices(); // Обновляем список услуг после закрытия ServiceEdit
  };

  if (selectedServiceId) {
    return <ServiceEdit onBack={handleServiceEditClose} serviceId={selectedServiceId === 'new' ? undefined : selectedServiceId} />;
  }

  return (
    <>
      <Button onClick={onBack}>Назад</Button>
      <Button onClick={() => setSelectedServiceId('new')}>Создать новую услугу</Button>
      <List>
        {services.map((service) => (
          <ListItem key={service.id} disablePadding>
            <ListItemButton onClick={() => setSelectedServiceId(service.id || null)}>
              <ListItemText primary={service.name} secondary={service.description} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ServiceList;