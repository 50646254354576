import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, CircularProgress, Box } from '@mui/material';
import { UserService, OfficeService, ServiceData, Day } from 'shared-lib';
import BookingCalendar from './BookingCalendar';

const BookingPage: React.FC = () => {
  const { userId, serviceId } = useParams<{ userId: string; serviceId: string }>();
  const [service, setService] = useState<ServiceData | null>(null);
  const [availableSlots, setAvailableSlots] = useState<Day[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const userService = UserService.getInstance();
  const officeService = OfficeService.getInstance();

  useEffect(() => {
    const fetchServiceAndSlots = async () => {
      if (!userId || !serviceId) {
        setError('Invalid URL: userId or serviceId is missing');
        setLoading(false);
        return;
      }

      try {
        const fetchedService = await userService.getServiceById(userId, serviceId);
        setService(fetchedService);

        if (fetchedService.officeId) {
          const startDate = new Date();
          const endDate = new Date();
          endDate.setDate(endDate.getDate() + 30);

          const slots = await officeService.fetchAvailableSlotsForUser(
            serviceId,
            userId,
            startDate,
            endDate
          );
          setAvailableSlots(slots);
        }
      } catch (err) {
        console.error('Error fetching service or slots:', err);
        setError('Failed to load service information');
      } finally {
        setLoading(false);
      }
    };

    fetchServiceAndSlots();
  }, [userId, serviceId, userService, officeService]);

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !userId || !serviceId) {
    return (
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Error
        </Typography>
        <Typography>{error || 'Invalid URL parameters'}</Typography>
      </Container>
    );
  }

  if (!service) {
    return (
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Service not found
        </Typography>
        <Typography>The requested service could not be found.</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Book {service.name}
      </Typography>
      <Typography variant="body1" paragraph>
        {service.description}
      </Typography>
      <Typography variant="body2" paragraph>
        Duration: {service.duration} minutes
      </Typography>
      <Typography variant="body2" paragraph>
        Price: ${service.price}
      </Typography>
      <BookingCalendar 
        availableSlots={availableSlots} 
        serviceDuration={service.duration} 
        serviceId={serviceId}
        userId={userId}
      />
    </Container>
  );
};

export default BookingPage;