import React from 'react';
import { Paper, Typography, Box, Chip } from '@mui/material';
import { Booking, Office } from 'shared-lib';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface BookingItemProps {
  booking: Booking;
  offices: Office[];
  isToday?: boolean;
  isPast?: boolean;
  onClick: () => void;
}

const BookingItem: React.FC<BookingItemProps> = ({ booking, offices, isToday, isPast, onClick }) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'NEW':
        return 'success';
      case 'DRAFT':
        return 'warning';
      case 'CANCELLED':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'NEW':
        return 'Подтверждено';
      case 'DRAFT':
        return 'Черновик';
      case 'CANCELLED':
        return 'Отменено';
      default:
        return status;
    }
  };

  const formatPrice = (price: number) => {
    return `${(price / 100).toFixed(0)} ₽`;
  };

  const getOfficeName = (officeId: string) => {
    const office = offices.find(o => o.id === officeId);
    return office ? office.name : 'Неизвестный офис';
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2, backgroundColor: isToday ? 'rgba(255, 255, 0, 0.1)' : isPast ? 'rgba(0, 0, 0, 0.05)' : 'white', cursor: 'pointer' }} onClick={onClick}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="h6">Бронирование #{booking.hashid}</Typography>
        <Chip 
          label={getStatusText(booking.status)} 
          color={getStatusColor(booking.status) as "success" | "warning" | "default"}
          size="small"
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <LocationOnIcon sx={{ mr: 1, color: 'text.secondary' }} />
        <Typography variant="body1">{getOfficeName(booking.officeId)}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <EventIcon sx={{ mr: 1, color: 'text.secondary' }} />
        <Typography variant="body1">{formatDate(booking.reserved_on)}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} />
        <Typography variant="body1">{formatTime(booking.reserved_on)}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <AttachMoneyIcon sx={{ mr: 1, color: 'text.secondary' }} />
        <Typography variant="body1">Цена: {booking.totalPrice ? formatPrice(booking.totalPrice) : 'Не указана'}</Typography>
      </Box>
    </Paper>
  );
};

export default BookingItem;