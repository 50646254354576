import React, { useState, useEffect } from 'react';
import { Button, List, ListItem, ListItemText, Typography, Snackbar, Box, IconButton } from '@mui/material';
import { OfficeService, Office } from 'shared-lib';
import { useAuth } from 'shared-lib';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface OfficeSelectionProps {
  onBack: () => void;
}

const OfficeSelection: React.FC<OfficeSelectionProps> = ({ onBack }) => {
  const [offices, setOffices] = useState<Office[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { user } = useAuth();
  const officeService = OfficeService.getInstance();

  useEffect(() => {
    const fetchOffices = async () => {
      const fetchedOffices = await officeService.fetchOffices();
      setOffices(fetchedOffices);
    };
    fetchOffices();
  }, [officeService]);

  const handleCopyLink = (officeId: string) => {
    if (!user) return;

    const bookingLink = `${window.location.origin}/book/${user.uid}/${officeId}`;
    navigator.clipboard.writeText(bookingLink).then(() => {
      setSnackbarOpen(true);
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Ссылки для бронирования</Typography>
      </Box>
      <List>
        {offices.map((office) => (
          <ListItem key={office.id}>
            <ListItemText primary={office.name} secondary={office.description} />
            <Button 
              startIcon={<ContentCopyIcon />}
              onClick={() => handleCopyLink(office.id)}
            >
              Копировать ссылку
            </Button>
          </ListItem>
        ))}
      </List>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Ссылка скопирована в буфер обмена"
      />
    </>
  );
};

export default OfficeSelection;