import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Box, Button, Grid, CircularProgress } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Day, Spot, OfficeService } from 'shared-lib';
import { ru } from 'date-fns/locale';
import { isBefore, startOfDay, isSameDay, startOfMonth, endOfMonth, format } from 'date-fns';

interface BookingCalendarProps {
  availableSlots: Day[];
  serviceDuration: number;
  serviceId: string;
  userId: string;
}

const BookingCalendar: React.FC<BookingCalendarProps> = ({ availableSlots: initialAvailableSlots, serviceDuration, serviceId, userId }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<Spot | null>(null);
  const [availableSlots, setAvailableSlots] = useState<Day[]>(initialAvailableSlots);
  const [isLoading, setIsLoading] = useState(false);

  const officeService = OfficeService.getInstance();

  const fetchAvailableSlots = useCallback(async (date: Date) => {
    setIsLoading(true);
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);

    try {
      const slots = await officeService.fetchAvailableSlotsForUser(serviceId, userId, startDate, endDate);
      setAvailableSlots(slots);
    } catch (error) {
      console.error('Error fetching available slots:', error);
    } finally {
      setIsLoading(false);
    }
  }, [serviceId, userId, officeService]);

  useEffect(() => {
    if (selectedDate) {
      fetchAvailableSlots(selectedDate);
    }
  }, [selectedDate, fetchAvailableSlots]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setSelectedSlot(null);
    if (date) {
      fetchAvailableSlots(date);
    }
  };

  const handleSlotSelect = (slot: Spot) => {
    setSelectedSlot(slot);
  };

  const handleBooking = () => {
    if (selectedDate && selectedSlot) {
      // Здесь должна быть логика для создания бронирования
      console.log('Booking:', { date: selectedDate, slot: selectedSlot, serviceId, userId });
    }
  };

  const availableSlotsForSelectedDate = selectedDate
    ? availableSlots.find(day => day.date === format(selectedDate, 'yyyy-MM-dd'))?.spots || []
    : [];

  const isDateDisabled = useCallback((date: Date) => {
    const today = startOfDay(new Date());
    if (isBefore(date, today)) {
      return true;
    }
    return !availableSlots.some(day => isSameDay(new Date(day.date), date));
  }, [availableSlots]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <Box>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={selectedDate}
          onChange={handleDateChange}
          shouldDisableDate={isDateDisabled}
          onMonthChange={fetchAvailableSlots}
        />
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : selectedDate && (
          <Box mt={2}>
            <Typography variant="h6">Доступные слоты:</Typography>
            <Grid container spacing={1}>
              {availableSlotsForSelectedDate.map((slot: Spot, index: number) => (
                <Grid item key={index}>
                  <Button
                    variant={selectedSlot === slot ? "contained" : "outlined"}
                    onClick={() => handleSlotSelect(slot)}
                  >
                    {`${slot.start} - ${slot.end}`}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {selectedSlot && (
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleBooking}>
              Забронировать
            </Button>
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default BookingCalendar;