import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { 
  IconButton, 
  Button, 
  AppBar, 
  Toolbar, 
  Typography, 
  Box, 
  useTheme, 
  Avatar, 
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import TimeSlotSelector from './components/TimeSlotSelector/TimeSlotSelector';
import BookingsList from './components/BookingsList';
import Home from './components/Home';
import { Office } from 'shared-lib';
import Login from './components/Login';
import BottomAppBar from './components/BottomAppBar';
import Profile from './components/Profile';
import { AuthProvider, useAuth } from 'shared-lib';
import { OfficeService } from 'shared-lib';
import PersonIcon from '@mui/icons-material/Person';
import BookingDetails from './components/BookingDetails';
import Settings from './components/Settings';
import BookingPage from './components/BookingPage';

const AppContent: React.FC = () => {
  const { user, loading } = useAuth(); // Remove the unused signOut here
  const [selectedOffice, setSelectedOffice] = useState<Office | null>(null);
  const [isTimeSlotSelectorOpen, setIsTimeSlotSelectorOpen] = useState(false);
  const [offices, setOffices] = useState<Office[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const officeService = OfficeService.getInstance();

  // Function to get the display name
  const getDisplayName = () => {
    if (user && user.displayName) {
      const names = user.displayName.split(' ');
      return `${names[0]} ${names[1]?.charAt(0)}.`;
    }
    return 'Пользователь';
  };

  const fetchOffices = useCallback(async () => {
    try {
      const fetchedOffices = await officeService.fetchOffices();
      setOffices(fetchedOffices);
    } catch (error) {
      console.error("Error fetching offices:", error);
    }
  }, [officeService]);

  useEffect(() => {
    fetchOffices();
  }, [fetchOffices]);

  const handleSelectOffice = (office: Office) => {
    setSelectedOffice(office);
    setIsTimeSlotSelectorOpen(true);
  };

  const handleSelectSlot = (date: string, startTime: string, endTime: string, price: number) => {
    console.log(`Selected slot: ${date} ${startTime}-${endTime}, Price: ${price}`);
    setIsTimeSlotSelectorOpen(false);
  };

  // Компонент для защищенных маршрутов
  const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Мой офис на Садовой 82
          </Typography>
          {user ? (
            <Box>
              {isMobile ? (
                <IconButton
                  component={Link}
                  to="/profile"
                  sx={{ color: 'inherit' }}
                >
                  <Avatar sx={{ width: 32, height: 32, bgcolor: 'common.white', color: 'primary.main' }}>
                    <PersonIcon />
                  </Avatar>
                </IconButton>
              ) : (
                <Typography 
                  variant="body1" 
                  component={Link} 
                  to="/profile" 
                  sx={{ 
                    mr: 2, 
                    color: 'inherit', 
                    textDecoration: 'none', 
                    '&:hover': { 
                      textDecoration: 'underline', 
                      cursor: 'pointer' 
                    } 
                  }}
                >
                  {getDisplayName()}
                </Typography>
              )}
            </Box>
          ) : (
            <Button color="inherit" component={Link} to="/login">Войти</Button>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ pb: 7 }}>
        <Routes>
          <Route path="/" element={
            <Home 
              offices={offices} 
              onSelectOffice={handleSelectOffice}
            />
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/bookings" element={
            <ProtectedRoute>
              <BookingsList />
            </ProtectedRoute>
          } />
          <Route path="/profile" element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } />
          <Route path="/bookings/:bookingId" element={<BookingDetails />} />
          <Route path="/settings" element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          } />
          <Route path="/book/:userId/:serviceId" element={<BookingPage />} />
        </Routes>
        {selectedOffice && (
          <TimeSlotSelector
            office={selectedOffice}
            onSelectSlot={handleSelectSlot}
            open={isTimeSlotSelectorOpen}
            onClose={() => setIsTimeSlotSelectorOpen(false)}
            user={user}
          />
        )}
      </Box>
      <BottomAppBar />
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
};

export default App;