import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, Button, TextField, Paper, 
  IconButton, CircularProgress, Snackbar, Fab, Container,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Accordion, AccordionSummary, AccordionDetails, Select, MenuItem, FormControl, InputLabel,
  Tooltip, Card, CardContent
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAuth, UserService, OfficeService, ServiceData, DateOverride } from 'shared-lib';
import { useUnsavedChangesWarning } from '../hooks/useUnsavedChangesWarning';
import { ru } from 'date-fns/locale';
import { Office } from 'shared-lib';

const ServiceEdit: React.FC<{ onBack: () => void, serviceId?: string }> = ({ onBack, serviceId }) => {
  const [service, setService] = useState<ServiceData>({
    name: '',
    description: '',
    duration: 60,
    price: 0,
    officeId: null,
    userId: ''
  });
  const [dateOverrides, setDateOverrides] = useState<DateOverride[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [deletedDays, setDeletedDays] = useState<string[]>([]);
  const [copySuccess, setCopySuccess] = useState('');

  const { user } = useAuth();
  const userService = UserService.getInstance();
  const officeService = OfficeService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      
      try {
        setLoading(true);
        const fetchedOffices = await officeService.fetchOffices();
        setOffices(fetchedOffices);

        if (serviceId) {
          const serviceData = await userService.getServiceById(user.uid, serviceId);
          setService(serviceData);
          const fetchedDateOverrides = await userService.getServiceDateOverrides(user.uid, serviceId);
          setDateOverrides(fetchedDateOverrides);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, userService, officeService, serviceId]);

  const handleAddDate = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDate(null);
  };

  const handleConfirmDate = () => {
    if (selectedDate) {
      const dateString = selectedDate.toISOString().split('T')[0];
      if (!dateOverrides.find(override => override.id === dateString)) {
        const newOverride: DateOverride = { 
          id: dateString, 
          intervals: []
        };
        setDateOverrides(prev => [...prev, newOverride]);
        setIsChanged(true);
      }
    }
    handleCloseDialog();
  };

  const handleServiceChange = (field: keyof ServiceData, value: string | number) => {
    setService(prev => ({ ...prev, [field]: value }));
    setIsChanged(true);
  };

  const handleIntervalChange = (overrideIndex: number, intervalIndex: number, field: 'start' | 'end', value: string) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: newOverrides[overrideIndex].intervals.map((interval, idx) => 
          idx === intervalIndex ? { ...interval, [field]: value } : interval
        )
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const addInterval = (overrideIndex: number) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: [...newOverrides[overrideIndex].intervals, { start: '09:00', end: '18:00' }]
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const removeInterval = (overrideIndex: number, intervalIndex: number) => {
    setDateOverrides(prev => {
      const newOverrides = [...prev];
      newOverrides[overrideIndex] = {
        ...newOverrides[overrideIndex],
        intervals: newOverrides[overrideIndex].intervals.filter((_, idx) => idx !== intervalIndex)
      };
      return newOverrides;
    });
    setIsChanged(true);
  };

  const removeDay = (overrideIndex: number) => {
    const dayToRemove = dateOverrides[overrideIndex];
    setDateOverrides(prev => prev.filter((_, idx) => idx !== overrideIndex));
    setDeletedDays(prev => [...prev, dayToRemove.id]);
    setIsChanged(true);
  };

  const handleSave = async () => {
    if (!user) return;
    
    try {
      setSaving(true);
      const serviceWithUserId = {
        ...service,
        userId: user.uid,
      };

      // Сначала сохраняем услугу
      const savedService = await userService.saveService(user.uid, serviceWithUserId);
      
      // Теперь у нас есть ID услуги, даже если это была новая услуга
      const serviceId = savedService.id;

      // Сохраняем переопределения дат
      if (serviceId) {
        await userService.saveServiceDateOverrides(user.uid, serviceId, dateOverrides);
      
        // Удаляем удаленные дни
        for (const date of deletedDays) {
          await userService.deleteServiceDateOverride(user.uid, serviceId, date);
        }
      }
      
      setError('Сервис успешно сохранен');
      setIsChanged(false);
      setDeletedDays([]);
      
      // Обновляем ID услуги в состоянии, если это была новая услуга
      if (!service.id) {
        setService(prev => ({ ...prev, id: serviceId }));
      }
    } catch (error) {
      console.error('Error saving service:', error);
      setError('Не удалось сохранить сервис. Пожалуйста, попробуйте еще раз.');
    } finally {
      setSaving(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBack = () => {
    if (isChanged) {
      // Show confirmation dialog
      if (window.confirm('У вас есть несохраненные изменения. Вы уверены, что хотите вернуться?')) {
        onBack();
      }
    } else {
      onBack();
    }
  };

  useUnsavedChangesWarning(() => isChanged ? "You have unsaved changes. Are you sure you want to leave?" : null);

  const getServiceUrl = () => {
    return `${window.location.origin}/book/${user?.uid}/${service.id}`;
  };

  const copyServiceUrl = () => {
    const url = getServiceUrl();
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess('Ссылка скопирована!');
      setTimeout(() => setCopySuccess(''), 2000);
    }, (err) => {
      console.error('Не удалось скопировать: ', err);
    });
  };

  const openServiceInNewTab = () => {
    const url = getServiceUrl();
    window.open(url, '_blank');
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <Container sx={{ mt: 4 }}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mb: 2 }}>
          Назад
        </Button>

        {service.id && (
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Ссылка на услугу
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                  {getServiceUrl()}
                </Typography>
                <Box>
                  <Tooltip title="Скопировать ссылку">
                    <IconButton onClick={copyServiceUrl} color="primary">
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Открыть в новой вкладке">
                    <IconButton onClick={openServiceInNewTab} color="primary">
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}

        <Box sx={{ pb: 10 }}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Основная информация</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                label="Название сервиса"
                value={service.name}
                onChange={(e) => handleServiceChange('name', e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Описание"
                value={service.description}
                onChange={(e) => handleServiceChange('description', e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                type="number"
                label="Длительность (минуты)"
                value={service.duration}
                onChange={(e) => handleServiceChange('duration', parseInt(e.target.value))}
                sx={{ mb: 2, mr: 2 }}
              />
              <TextField
                type="number"
                label="Цена"
                value={service.price}
                onChange={(e) => handleServiceChange('price', parseFloat(e.target.value))}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Офис</InputLabel>
                <Select
                  value={service.officeId || ''}
                  onChange={(e) => handleServiceChange('officeId', e.target.value)}
                >
                  <MenuItem value="">
                    <em>Не выбран</em>
                  </MenuItem>
                  {offices.map((office) => (
                    <MenuItem key={office.id} value={office.id}>
                      {office.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Расписание</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddDate}
                sx={{ mb: 2 }}
              >
                Добавить дату
              </Button>
              {dateOverrides.map((override, overrideIndex) => (
                <Box key={override.id} sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {new Date(override.id).toLocaleDateString('ru-RU', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </Typography>
                    <IconButton onClick={() => removeDay(overrideIndex)} size="small">
                      <Typography variant="caption" color="error">Удалить день</Typography>
                    </IconButton>
                  </Box>
                  <Box sx={{ pl: 2 }}>
                    {override.intervals.map((interval, intervalIndex) => (
                      <Box key={intervalIndex} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <TextField
                          type="time"
                          value={interval.start}
                          onChange={(e) => handleIntervalChange(overrideIndex, intervalIndex, 'start', e.target.value)}
                          sx={{ width: 110, mr: 1 }}
                          inputProps={{ style: { padding: '8px' } }}
                        />
                        <TextField
                          type="time"
                          value={interval.end}
                          onChange={(e) => handleIntervalChange(overrideIndex, intervalIndex, 'end', e.target.value)}
                          sx={{ width: 110, mr: 1 }}
                          inputProps={{ style: { padding: '8px' } }}
                        />
                        <IconButton onClick={() => removeInterval(overrideIndex, intervalIndex)} size="small">
                          <Typography variant="caption" color="error">Удалить</Typography>
                        </IconButton>
                      </Box>
                    ))}
                    <Button onClick={() => addInterval(overrideIndex)} variant="outlined" sx={{ mt: 1 }} size="small">
                      Добавить интервал
                    </Button>
                  </Box>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
        
        <Fab 
          color="primary" 
          size="small" 
          onClick={scrollToTop}
          sx={{ position: 'fixed', bottom: 80, right: 16 }}
        >
          <KeyboardArrowUpIcon />
        </Fab>

        <Paper 
          elevation={3} 
          sx={{ 
            position: 'fixed', 
            bottom: 0, 
            left: 0, 
            right: 0, 
            p: 2, 
            display: 'flex', 
            justifyContent: 'flex-end',
            backgroundColor: 'background.paper',
            zIndex: 1100,
          }}
        >
          <Button 
            variant="contained" 
            onClick={handleSave} 
            disabled={saving || !isChanged}
            startIcon={<SaveIcon />}
          >
            {saving ? 'Сохранение...' : 'Сохранить сервис'}
          </Button>
        </Paper>

        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          message={error}
        />

        <Snackbar
          open={!!copySuccess}
          autoHideDuration={2000}
          onClose={() => setCopySuccess('')}
          message={copySuccess}
        />

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Выберите дату</DialogTitle>
          <DialogContent>
            <DatePicker
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Отмена</Button>
            <Button onClick={handleConfirmDate} disabled={!selectedDate}>
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </LocalizationProvider>
  );
};

export default ServiceEdit;