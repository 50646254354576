import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Box, List, ListItem, ListItemIcon, ListItemText, Divider, Button, CircularProgress, Chip, ListItemButton } from '@mui/material';
import { Schedule as ScheduleIcon, CalendarToday as CalendarIcon } from '@mui/icons-material';
import { useAuth } from 'shared-lib';
import { UserService } from 'shared-lib';
import { getFunctions, httpsCallable } from 'firebase/functions';
import OfficeSelection from './OfficeSelection';
import ServiceList from './ServiceList';

const Settings: React.FC = () => {
  const [isGoogleCalendarConnected, setIsGoogleCalendarConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOfficeSelection, setShowOfficeSelection] = useState(false);
  const [showServiceEdit, setShowServiceEdit] = useState(false);
  const { user } = useAuth();
  const userService = UserService.getInstance();
  const functions = getFunctions(undefined, 'europe-west3');

  const checkGoogleCalendarConnection = useCallback(async () => {
    if (!user) return;

    setLoading(true);
    try {
      const userData = await userService.getUserData(user.uid);
      setIsGoogleCalendarConnected(userData.googleCalendarConnected || false);
    } catch (error) {
      console.error('Error checking Google Calendar connection:', error);
    } finally {
      setLoading(false);
    }
  }, [user, userService]);

  useEffect(() => {
    checkGoogleCalendarConnection();
  }, [checkGoogleCalendarConnection]);

  const handleConnectGoogleCalendar = async () => {
    if (!user) return;

    setLoading(true);
    try {
      const initiateGoogleAuth = httpsCallable(functions, 'initiateGoogleAuth');
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const result = await initiateGoogleAuth({ 
        entityId: user.uid, 
        entityType: 'users', 
        redirectUri: `${baseUrl}/oauth2callback` 
      });
      const { authUrl } = result.data as { authUrl: string };
      
      const newWindow = window.open(authUrl, '_blank', 'width=600,height=600');
      
      // Poll the new window to check if it's closed
      const pollTimer = setInterval(() => {
        if (newWindow?.closed) {
          clearInterval(pollTimer);
          checkGoogleCalendarConnection();
        }
      }, 500);

    } catch (error) {
      console.error('Error initiating Google Calendar auth:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'GOOGLE_CALENDAR_CONNECTED') {
        checkGoogleCalendarConnection();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [checkGoogleCalendarConnection]);

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4, mb: 8, px: 2 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Настройки
      </Typography>
      {!showOfficeSelection && !showServiceEdit ? (
        <List>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText 
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Google Calendar
                  {isGoogleCalendarConnected && (
                    <Chip 
                      label="Подключено" 
                      color="success" 
                      size="small" 
                      sx={{ ml: 1 }}
                    />
                  )}
                </Box>
              }
              secondary={isGoogleCalendarConnected ? "Подключено" : "Не подключено"}
            />
            <Button
              onClick={handleConnectGoogleCalendar}
              disabled={loading}
              variant={isGoogleCalendarConnected ? "outlined" : "contained"}
              color="primary"
            >
              {loading ? <CircularProgress size={24} /> : (isGoogleCalendarConnected ? "Переподключить" : "Подключить")}
            </Button>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={() => setShowOfficeSelection(true)}>
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText primary="Ссылки для бронирования" secondary="Создание ссылок для бронирования" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setShowServiceEdit(true)}>
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText primary="Управление услугами" secondary="Создание и редактирование услуг" />
            </ListItemButton>
          </ListItem>
        </List>
      ) : showOfficeSelection ? (
        <OfficeSelection onBack={() => setShowOfficeSelection(false)} />
      ) : (
        <ServiceList onBack={() => setShowServiceEdit(false)} />
      )}
    </Box>
  );
};

export default Settings;