import React, { useState } from 'react';
import { Typography, Box, Button, Divider, TextField, CircularProgress, IconButton } from '@mui/material';
import { SelectedSlot } from 'shared-lib';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';

interface ConfirmationStepProps {
  selectedSlot: SelectedSlot;
  onConfirm: (comment: string) => Promise<void>;
  onClose: () => void;
}

const ConfirmationStep: React.FC<ConfirmationStepProps> = ({ selectedSlot, onConfirm, onClose }) => {
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const formatPrice = (price: number) => `${(price / 100).toFixed(0)} ₽`;
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', { weekday: 'long', day: 'numeric', month: 'long' });
  };

  const calculateDuration = (start: string, end: string) => {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);
    const durationInMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours}ч ${minutes > 0 ? minutes + 'мин' : ''}`;
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onConfirm(comment);
      onClose(); // Close the confirmation after successful booking
    } catch (error) {
      console.error('Booking failed:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Подтверждение бронирования
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <EventIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Дата: {formatDate(selectedSlot.date)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTimeIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Время: {selectedSlot.spot.start} - {selectedSlot.spot.end}, {calculateDuration(selectedSlot.spot.start, selectedSlot.spot.end)}
          </Typography>
        </Box>
      </Box>
      <TextField
        fullWidth
        label="Комментарий"
        multiline
        rows={4}
        value={comment}
        onChange={handleCommentChange}
        variant="outlined"
        margin="normal"
      />
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="body1">Всего</Typography>
        <Typography variant="body1" fontWeight="bold">
          {formatPrice(selectedSlot.spot.prices)}
        </Typography>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Button 
          onClick={handleConfirm}
          variant="contained" 
          color="primary" 
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Онлайн-запись'
          )}
        </Button>
      </Box>
      <Typography variant="caption" sx={{ display: 'block', mt: 2, textAlign: 'center' }}>
        Записываясь, вы даете согласие на обработку своих персональных данных.
      </Typography>
    </Box>
  );
};

export default ConfirmationStep;